body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
}

#game {
  width: 100%;
  max-width: var(--game-max-width);
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: var(--color-tone-1);
  border-bottom: 1px solid var(--color-tone-4);
}

header .title {
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
}

#board-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.row {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem;
}

.tile {
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  margin: 0 0.125rem;
  width: 3.5rem;
  height: 3.5rem;
}